import React from "react"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import MuiLink from "@material-ui/core/Link"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import CallOutlinedIcon from "@material-ui/icons/CallOutlined"
import LocationOnIcon from "@material-ui/icons/LocationOn"

import Layout from "../components/layout"
import Head from "../components/head"
import heroContactSvg from "../assets/contact.svg"

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(15, 0, 12),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(9, 0, 4),
    },
  },
  heroGridContainer: {
    display: "grid",
    gridGap: theme.spacing(6),
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 1fr",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  heroImage: {
    width: "100%",
  },
  heroGridItemImage: {
    textAlign: "center",
  },
  bottomMargin: {
    marginBottom: theme.spacing(6),
  },
  logos: {
    width: "80px",
  },
  iconWrap: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  contactDetail: {
    margin: theme.spacing(7, 0),
  },
}))

const Contact = () => {
  const classes = useStyles()

  return (
    <Layout>
      <Head title="Contact" />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="lg">
            <div className={classes.heroGridContainer}>
              <div>
                <div className={classes.bottomMargin}>
                  <Typography
                    component="h1"
                    variant="h2"
                    color="textPrimary"
                    gutterBottom
                  >
                    Contact Us
                  </Typography>
                  <Typography paragraph>
                    Our office is conveniently located in the 1/119A John Street
                    Cabramatta, NSW. You can contact us by calling in to our
                    office <strong>from 9am to 6pm, Monday to Friday</strong>.
                    Appointments can be made outside these hours if necessary.
                    Please also feel free to email us with any queries you have.
                  </Typography>

                  <div className={classes.contactDetail}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <div className={classes.iconWrap}>
                          <MailOutlineIcon />
                        </div>
                      </Grid>
                      <Grid item>
                        <MuiLink
                          href="mailto:janicevusolicitor@gmail.com"
                          variant="body1"
                        >
                          janicevusolicitor@gmail.com
                        </MuiLink>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item>
                        <div className={classes.iconWrap}>
                          <CallOutlinedIcon />
                        </div>
                      </Grid>
                      <Grid item>
                        <MuiLink href="tel: 0297275459" variant="body1">
                          (02) 9727 5459
                        </MuiLink>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item>
                        <div className={classes.iconWrap}>
                          <LocationOnIcon />
                        </div>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          1/119a John Street Cabramatta, NSW 2166
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>

              <div className={classes.heroGridItemImage}>
                <img
                  src={heroContactSvg}
                  alt="Contact us"
                  className={classes.heroImage}
                />
              </div>
            </div>
          </Container>
        </div>
      </main>
    </Layout>
  )
}

export default Contact
